.top-bar{
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    /*border: 1px solid $white;
    border-radius: 1rem;*/
    width: 100%;
    padding: 1rem 0;
    color: $white;
    @include shift(padding);
    font-size: 1.5rem;
    &.project-bar{
        opacity: 0;
        animation: opacity 0.5s ease-in forwards;
        padding: 1.5rem 0;
    }
    &.fixed-top{
        background: $topbarfixedbg;
        color: $fontcolor;
        position: fixed;
        z-index: 99;
        width: 100%;
        opacity: 0;
        animation: opacity 0.5s ease-in forwards;
        padding: 1.5rem 0;
        //box-shadow: 0 0 5px $shadowcolor;
        .top-bar-inner{
            .main-nav{
                ul{
                    li{
                        &.has-submenu{
                            & > a{
                                &::after{
                                    border-right: 2px solid $fontcolor;
                                    border-bottom: 2px solid $fontcolor;
                                }
                            }
                        }
                    }
                }
                a{
                    color:$white;
                    @media screen and (min-width: $lg){
                        color: $fontcolor;
                    }
                    &::before{
                        background: $fontcolor;
                    }
                }
            }
        }
        
    }
    .top-bar-inner{
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        @media screen and (min-width: $lg){
            flex-wrap: nowrap;
        }
        .menu-toggler{
            width: 3rem;
            height: 3rem;
            padding: 0.5rem;
            border: 0;
            background: $menutogglerbg;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            cursor: pointer;
            @media screen and (min-width: $lg){
                display: none;
            }
            .toggler-bar{
                display: block;
                height: 2px;
                background: $menutogglerbarcolor;
                width: 100%;
                @include shift(transform)
            }
            &.close{
                .toggler-bar{
                    &:nth-child(1){
                        transform: rotate(45deg)
                    }
                    &:nth-child(2){
                        display: none;
                    }
                    &:nth-child(3){
                        transform: rotate(-45deg)
                    }
                }
            }
        }
        /*.languages{
            display: flex;
            a{
                color: $languagecolor;
                display: block;
                padding: 0.5rem;
                border: 1px solid #fff;
                margin: 0 0.2rem;
                &.active, &:hover{
                    background: $languageactivebg;
                    color: $languageactivecolor;
                }
            }
        }*/
        .main-nav{
            width: 100%;
            position: absolute;
            top: 0;
            left: -100%;
            background: $black;
            height: 100vh;
            padding-top: 3rem;
            @include shift(left);
            @media screen and (min-width: $lg){
                width: auto;
                position: static;
                background: transparent;
                padding-top: 0;
                height: auto;
            }
            &.mobile-visible{
                left: 0;
            }
            .menu-close{
                position: absolute;
                top: 1rem;
                right: 1rem;
                background: transparent;
                border: none;
                width: 2rem;
                height: 2rem;
                cursor: pointer;
                &::before, &::after{
                    content:'';
                    display: block;
                    width: 100%;
                    height: 1px;
                    background: $menuclosecolor;
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: rotate(45deg)
                }
                &::after{
                    transform: rotate(-45deg)
                }
                @media screen and (min-width: $lg){
                    display: none;
                }
            }
            
            ul{
                display: flex;
                flex-direction: column;
                @media screen and (min-width: $lg){
                    flex-direction: row;
                }
                &.submenu{
                    background: $verylightgrey;
                    display: none;
                    @media screen and (min-width: $lg){
                        position: absolute;
                        top: 100%;
                        left: 50%;
                        transform: translateX(-50%);
                    }
                    &.visible{
                        display: flex;
                        opacity: 0;
                        animation: opacity 0.5s ease-in forwards;
                    }
                    a{
                        color: $fontcolor;
                    }
                }
                li{
                    position: relative;
                    &.has-submenu{
                        & > a{
                            position: relative;
                            &::after{
                                content:'';
                                display: block;
                                width: 0.5rem;
                                height: 0.5rem;
                                border: 2px solid $white;
                                border-top: 0;
                                border-left: 0;
                                position: absolute;
                                top: 50%;
                                right: 0;
                                transform: translateY(-50%) rotate(45deg);
                                @include shift(transform);
                            }
                        }
                       
                        &.open{
                            & > a{
                                &::after{
                                    transform: rotate(-135deg);
                                }
                            }
                        }
                    }
                }
            }
            a{
                color: $white;
                display: inline-block;
                padding: 0.5rem 1rem;
                position: relative;
                &::before{
                    content:'';
                    display: block;
                    width: 0;
                    height: 1px;
                    background: $white;
                    position: absolute;
                    top: 100%;
                    left: 50%;
                    transform: translateX(-50%);
                    @include shift(width);
                }
                &:not(.active):hover{
                    &::before{
                        width: 80%;
                    }
                }
                
            }
        }
    }
}

/*.main-nav{
    display: none;
    position: fixed;
    top: 0;
    z-index: 100;
    width: 100%;
    height: 100vh;
    background: $menubg;
    padding: 2rem 1rem;
    overflow-y: scroll;
    @media screen and (min-width: $lg){
        padding: 2rem;
    }
    img{
        margin:auto;
        display: block;
        width: 12rem;
    }
    .menu-close{
        position: absolute;
        top: 2rem;
        right: 2rem;
        background: transparent;
        border: none;
        width: 2rem;
        height: 2rem;
        cursor: pointer;
        &::before, &::after{
            content:'';
            display: block;
            width: 100%;
            height: 1px;
            background: $menuclosecolor;
            position: absolute;
            top: 50%;
            left: 0;
            transform: rotate(45deg)
        }
        &::after{
            transform: rotate(-45deg)
        }
    }
    & > ul{
        display: flex;
        flex-wrap: wrap;
        color: $fontcolor;
        //justify-content: space-between;
        & > li{
            width: 100%;
            padding: 1rem;
            @media (orientation: landscape){
                width: 50%;
            }
            @media screen and (min-width: $md){
                width: 50%;
            }
            @media screen and (min-width: $lg){
                width: 25%;
                padding: 2rem 1rem;
            }
            &.special{
                background: $menuspecialbg;
                position: relative;
                overflow: clip;
                &::before, &::after{
                    content:'';
                    display: block;
                    position: absolute;
                    top: 0%;
                    left: 100%;
                    width: 10rem;
                    height: 10rem;
                    border-radius: 50%;
                    transform: translate(-80%, -20%);
                    background: $menuspecialbeforebg;
                    z-index: 2;
                }
                &::after{
                    transform: translate(-90%, -60%);
                    background: $menuspecialafterbg;
                    top: 80%;
                    left: 100%;
                    width: 15rem;
                    height: 15rem;
                    z-index: 1;
                }
                p{
                    font-size: 1.15rem;
                    position: relative;
                    z-index: 3;
                    a{
                        color: $darkgrey;
                        text-decoration: underline;
                    }
                }
            }
            & > a{
                font-weight: 600;
                color: inherit;
                display: block;
                padding: 0.5rem 0;
                text-transform: uppercase;
            }
            & > ul{
                & > li{
                    line-height: 1.2;
                    & > a{
                        color: inherit;
                        display: inline-block;
                        padding: 0.25rem 0;
                        position: relative;
                    }
                }
            }
        }
       
    }
}*/

/*nav{
    position: absolute;
    width: 100%;
    left: 0;
    top:100%;
    z-index: 2;
    background: $white;
    display: none;
    @media screen and (min-width: $lg){
        flex-grow: 1;
        width: calc(80% - 15rem);
        padding: 0 2rem;
        position: static;
        background: transparent;
        display:block;
    }
    .cross{
        position: absolute;
        top: 1rem;
        right: 1rem;
        width: 1.5rem;
        height: 1.5rem;
        border: none;
        background: transparent;
        @media screen and (min-width: $lg){
            display: none;
        }
        .cross-bar{
            content:'';
            display: block;
            width: 100%;
            background: $fontcolor;
            height: 2px;
            position: absolute;
            top: 50%;
            &:first-child{
                transform: rotate(45deg);
            }
            &:last-child{
                transform: rotate(-45deg);
            }
        }
    }
    &.shown{
        display: block;
        padding-top: 2rem;
    }
   
    .main-menu{
        display:flex;
        flex-direction: column;
        @media screen and (min-width: $lg){
            flex-direction: row;
            justify-content: flex-end;
        }
        
        & > li{
            position: relative;
            &::after{
                content: '';
                display: none;
                width: 1px;
                height: 15px;
                background: $menulinkhovbg;
                position: absolute;
                left: 100%;
                top: 50%;
                transform: translateY(-50%);
                @media screen and (min-width: $lg){
                    display: block;
                }

            }
            &:last-child{
                &::after{
                    display: none;
                }
            }
            & > a{
                display: block;
                padding: 0.8rem;
                color: $menulinkcolor;
                position: relative;
                @media screen and (min-width: $lg){
                    margin: 0 5px;
                }
                &:hover{
                    @media screen and (min-width: $lg){
                        background: $menulinkhovbg;
                        color: $menulinkhovcolor;
                    }
                }
                &[aria-haspopup=true]{
                    &:after{
                        position: absolute;
                        right: 1rem;
                        top: 50%;
                        font-size: 1rem;
                        transform: translatey(-50%);
                        @media screen and (min-width: $lg){
                            display: none;
                        }

                    }
                    &[aria-expanded=false]{
                        &:after{
                            content:'\276F';
                        }
                    }
                    &[aria-expanded=true]{
                        &:after{
                            content:'\276E';
                        }
                    }
                }
            }
            & > ul{
                display : none;
                background: $submenubg;
                @media screen and (min-width: $lg){
                    position: absolute;
                    z-index: 99;
                    top: 100%;
                    left: 0;
                    width: 130%;
                }
                &.open{
                    display: block;
                }
                & > li{
                    & > a{
                        display: block;
                        padding: 0.8rem 1.2rem;
                        color: $submenulinkcolor;
                        &:hover{
                            color: $submenulinkhovcolor;
                            background: $submenulinkhovbg;
                        }
                    }
                }
            }
            &:hover{
                & > ul {
                    @media screen and (min-width: $lg){
                        display: block;
                    }
                }
            }
        }
    }
}*/

.highcontrast{
    nav{
        background: $bodybgcontra;
        .main-menu{
            & > li{
                & > a{
                    color: $fontcolorcontra;
                }
                & > ul{
                    background: $bodybgcontra;
                     & > li{
                        & > a{
                            color: $fontcolorcontra;
                        }
                    }
                }
            }
        }
    }
}
