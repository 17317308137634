//@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;900&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Marcellus&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@500;600&display=swap');

$red: #c00;
$cardinal: #d2223b;
$darkgrey: #222629;
$darknavy: #424A55;
//$darknavy:#0f373f;
$grey: #474B4F;
$lightgrey: #6A6E71;
$verylightgrey: #eee;
$beige: #ffcc80;
$black: #000;
$white: #fff;
$orange_base:#fd6826;
$orange: #db704d;
$lightyellow: #ffcc66;
$shadowcolor: lighten($lightgrey, 20%);
$transnavy1: rgba($darknavy, 0.8);
$transnavy2: rgba(darken($darknavy,20), 0.8);
$transblack: rgba($black, 0.5);
$transwhite: rgba($white, 0.5);
$transblackcontra: rgba($black, 0.8);
$transwhitecontra: rgba($white, 0.8);
$bodybg: $white;
$bodybgcontra: $black;
$linebg: #212121;
//$fontcolor: lighten($black, 15%);
$fontcolor: $black;
$fontcolorcontra: yellow;
$generallinkcolor: $black;
$topbarfixedbg: $white;
$languagecolor: $white;
$languageactivebg: $white;
$languageactivecolor: $darknavy;
$languagefixedcolor: $darknavy;
$languagefixedactivebg: $darknavy;
$languagefixedactivecolor: $white;

$mainheadercolor: $white;
$mainheaderbeforebg: $transnavy2;
$mainheaderafterbg: $transnavy1;
$mainheadercirclebg: $orange;
$headerbg: lighten($lightgrey, 50%);
$headerbgcontra: $black;
$menutogglerbg: $darkgrey;
$menutogglerbarcolor: $white;
$menuclosecolor: $white;
$menubg: $white;
$menuspecialbg: lighten($darknavy, 60%);
$menuspecialbeforebg: lighten($orange, 30%);
$menuspecialafterbg: lighten($darknavy, 50%);
$menulinkcolor: $fontcolor;
$menulinkhovbg: $fontcolor;
$menulinkhovcolor: $white;
$submenubg: lighten($lightgrey, 55%);
$submenulinkcolor: darken($lightgrey, 10%);
$submenulinkhovcolor: $white;
$submenulinkhovbg: $lightgrey;
$footerbg: $white;
$footercolor: $fontcolor;


//$bodyfont: 'Montserrat', sans-serif;
//$headerfont: 'Marcellus', serif;
$headerfont: 'Work Sans', sans-serif;
$bodyfont: 'Helvetica', sans-serif;
$containerwidthmid: 95%;
$containerwidth: 95%;
$radius: 0;

$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1600px
