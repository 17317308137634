@keyframes opacity {
    from {opacity: 0}
    to {opacity: 1}
}
.to-fade-in {
    opacity: 0;
    &.fade-in {
      animation: opacity 0.5s forwards;
    }
  }
.main-header{
    height: 100vh;
    background-repeat: no-repeat;
    //background-attachment: fixed;
    background-position: center center;
    background-size: cover;
    position: relative;
    //max-height: 1080px;
    .top-bar{
        &.fixed-top{
            background: transparent;
            color: $white;
            box-shadow: none;
            width: 42%;
            margin-left: 2.5%;
           /* @media screen and (min-width: 1921px){
                margin-left: calc((100% - 1920px)/2 + 2.5%);
            }*/
            .container-custom{
                width: 100%;
            }
        }
    }
   /* &::before{
        content:'';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        background: $transblack;
        z-index: 0;
    }*/
    /*.main-header-content{
        display: flex;
        align-items: center;
        flex-direction: column-reverse;
        justify-content: space-evenly;
        height: 100%;
        @media screen and (min-width: $lg){
            justify-content: center;
        }
        h1{
            color: $mainheadercolor;
            z-index: 1;
            width: 70%;
            font-size: 2rem;
            font-family: $headerfont;
            font-weight: 300;
            position: relative;
            @media screen and (min-width: $lg){
                font-size: 3rem;
            }
            @media screen and (min-width: $xl){
                font-size: 4.5rem;
            }
            @media screen and (min-width: $xxl){
                font-size: 5rem;
            }
            
            &::before, &::after{
                content:'';
                width: 80%;
                height: 80%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-60%,-50%) rotate(5deg) skewX(5deg);
                background: $mainheaderbeforebg;
                animation: opacity 1s ease-in 3.5s forwards;
                opacity: 0; 
            }
            &::after{
                transform: translate(-50%,-50%) rotate(-10deg) skewX(-5deg);
                background: $mainheaderafterbg;
                animation: opacity 1s ease-in 4.5s forwards;
                z-index: -1;

            }
            span{
                display: block;
                line-height: 1.2;
                opacity: 0;
                &:nth-child(1){
                    animation: opacity 2s ease-in 0.5s forwards;
                    position: relative;
                    &::before{
                        content: '';
                        display: block;
                        width: 2.5rem;
                        height: 2.5rem;
                        background: $mainheadercirclebg;
                        position: absolute;
                        top: 0;
                        left: 70%;
                        opacity: 0;
                        border-radius: 100%;
                        animation: opacity 3s ease-in 5.5s forwards;
                        @media screen and (min-width: $lg){
                            width: 6rem;
                            height: 6rem;
                        }
                    }
                }
                &:nth-child(2){
                    padding-left: 17%;
                    animation: opacity 2s ease-in 1.5s forwards;
                }
                &:nth-child(3){
                    padding-left: 5%;
                    animation: opacity 2s ease-in 2.5s forwards;
                    @media screen and (min-width: $lg){
                        padding-left: 50%;
                    }
                }
            }
        }
        img{
            z-index: 1;
            width: 8rem;
        }
    }*/
}
.info-bar{
    position: fixed;
    bottom: 0; 
    right: 0;
    z-index: 10;
    color: $white;
    font-size: 2rem;
    font-family: $headerfont;
    font-weight: 600;
    text-transform: uppercase;
    //width: 100%;
    padding: 1rem 2.5% 1rem 0;
    opacity: 0;
    animation: opacity 0.5s ease-in forwards;
    @media screen and (min-width: $md){
        font-size: 3.125rem;
    }
    /*@media screen and (min-width: 1921px){
        margin-right: calc((100% - 1920px)/2);
    }*/
    li{
        text-align: right;
    }
    a{
        color: inherit;
    }
}
.subpage-header{
    .top-bar{
        position: relative;
        color: $fontcolor;
        .top-bar-inner{
            .main-nav{
                .has-submenu{
                    & > a{
                        &::after{
                            border-color: $fontcolor;
                        }
                    }
                }
                a{
                    color: $white;
                    @media screen and (min-width: $md){
                        color: inherit
                    }
                    &::before{
                        background: $fontcolor;
                    }
                    &.active{
                        &::before{
                            width: 80%;
                        }
                    }
                }
            }
        }
    }
}

.homeslider{
    height: 100%;
    width: 100%;
    & > div{
        height: 100%;
        width: 100%; 
    }
    .slick-track{
        height: 100%;
    }
    img,video{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    
}

/*.subpage-header{
    height: 50vh;
    background-position: center center;
    
    .subpage-header-content{
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        h2{
            font-weight: 600;
            color: $white;
            font-size: 2.5rem;
            position: relative;
            z-index: 1;
            font-family: $headerfont;
            padding-top: 5rem;
            text-align: center;
            width: 80%;
            @media screen and (min-width: $lg){
                font-size: 4rem;
            }
            span{
                display: block;
                font-size: 1.5rem;
                line-height: 1.5;
                @media screen and (min-width: $lg){
                    font-size: 2.5rem;
                }
            }
        }

    }
}*/
/*.noheight-header{
    height: 0;
    .subpage-header-content{
        h2{
            color: transparent;
        }
        
    }
   
}*/

/*.main-header{
    display: flex;
    align-items: center;
    background: $headerbg;
    position: relative;
    &.fixed{
        position: fixed;
        width: 100%;
        z-index: 10;
        top: 0;
        animation: opacity 0.5s;
        box-shadow: 0 0 10px $lightgrey;
    }
    
    .logo{
        width: 12rem;
        @media screen and (min-width: $lg){
            width: 15rem;
        }
    }
    .menu-toggler-wrapper{
        width: calc(80% - 15rem);
        display: flex;
        justify-content: flex-end;
        @media screen and (min-width: $lg){
            display: none;
        }
        .menu-toggler{
            width: 2rem;
            height: 2rem;
            padding: 0.3rem;
            border: 0;
            background: $darkgrey;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
           
            .toggler-bar{
                display: block;
                height: 2px;
                background: $white;
                width: 100%;
                @include shift(transform)
            }
            &.close{
                .toggler-bar{
                    &:nth-child(1){
                        transform: rotate(45deg)
                    }
                    &:nth-child(2){
                        display: none;
                    }
                    &:nth-child(3){
                        transform: rotate(-45deg)
                    }
                }
            }
        }
    }

    .access{
        flex-grow: 1;
        width: calc(20% - 15rem);
        display: flex;
        justify-content: space-evenly;
        img{
            width: 2.5rem;
            background: #fff;
            &:first-child{
                border-radius: 50%;
            }
        }
    }
}*/

/*.highcontrast{
    .main-header{
        background: $headerbgcontra;
        .access{
            img{
                border: 2px solid $fontcolorcontra;
            } 
        }
    }
}*/