.home-intro{
    @media screen and (min-width: $md){
        display: flex;
        justify-content: flex-end;
    }
    &_content{
        @media screen and (min-width: $md){
            font-size: 1.5rem;
            width: 60%;
        }
    }
}
.home-projects, .projects{
    @media screen and (min-width: $md){
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
    }
    &_item{
        padding: 1rem 0;
        @media screen and (min-width: $md){
            width: 60%;
        }
        .img-wrapper{
            position: relative;
            .project-name{
                position: absolute;
                top: 0;
                left: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                background: rgba($darkgrey, 0.8);
                color: $white;
                padding: 1rem 0;
                z-index: 1;
                @media screen and (min-width: $md){
                    height: 100%;
                    opacity: 0;
                    padding: 0;
                    @include shift(opacity);
                }
                @media screen and (min-width: $lg){
                    font-size: 1.5rem;
                }
                span{
                    @include shift(transform);
                    display: block;
                    text-align: center;
                    @media screen and (min-width: $md){
                        transform: translateY(-10px);
                    }
                }
            }
            &:hover{
                .project-name{
                    @media screen and (min-width: $md){
                        opacity: 1;
                    }
                    span{
                        @media screen and (min-width: $md){
                            transform: translateY(0);
                        }
                    }
                }
                
            }
        }
        &.vertical{
            .img-wrapper{
                @media screen and (min-width: $md){
                    max-width: 60%;
                }
            } 
        }
       
        
    }
}
.home-projects_link{
    text-align: center;
    padding: 3rem 0;
    @media screen and (min-width: $md){
        font-size: 1.5rem;
    }
}

.home_gallery{
    display: flex;
    flex-direction: column;
    padding-top: 5px;
    @media screen and (min-width: $lg){
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-top: 10px;
    }

}
.home_gallery_block{
    /*padding-top: 10px;
    padding-bottom: 10px;*/
    .item{
        position: relative;
        overflow: hidden;
        width: 100%;
        margin-bottom: 5px;
        @media screen and (min-width: $lg){
            margin-bottom: 10px;
        }
        &::before{
            content:'';
            display: block;
        }
        a{
            @media screen and (min-width: $lg){
                display: block;
                height: 100%;
                position: absolute;
                top: 0;
                width: 100%;
            }
            &:hover, &:focus-visible{
                .project-name{
                    @media screen and (min-width: $lg){
                        opacity: 1;
                    }
                    span{
                        @media screen and (min-width: $lg){
                            transform: translateY(0);
                        }
                    }
                } 
            }
        }
        img{
            @media screen and (min-width: $lg){
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                max-width: unset;
            }
        }
        .project-name{
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            width: 100%;
            //background: rgba($darkgrey, 0.8);
            color: $white;
            padding: 1rem 2.5% 1rem 1rem;
            z-index: 3;
            height: 100%;
            opacity: 0;
            @include shift(opacity);
            font-size: 1rem;
            @media screen and (min-width: $md){
                font-size: 1.5rem;
            }
          
            span{
                //@include shift(transform);
                display: block;
                text-align: right;
                font-family: $headerfont;
                font-weight: 600;
                text-transform: uppercase;
                //transform: translateY(-10px);
               
                /*@media screen and (min-width: $xl){
                    transform: translateY(-10px);  
                }*/
            }
            &.touched_once{
                opacity: 1;
            }
        }
       
       
    }
    &.left{
        @media screen and (min-width: $lg){
            width: calc(60% - 5px);
        }
        .project-name{
            @media screen and (min-width: $lg){
                padding: 1rem 4.16% 1rem 1rem;
            }
            /*@media screen and (min-width: $xxl){
                padding: 1rem calc(5% + 12px) 1rem 1rem;
            }*/
        }
        
    }
    &.right{
        @media screen and (min-width: $lg){
            width: calc(40% - 5px);
            display: flex;
            flex-direction: column;
        }
        .project-name{
            @media screen and (min-width: $lg){
                padding: 1rem 6.25% 1rem 1rem;
            }
            /*@media screen and (min-width: $xxl){
                padding: 1rem calc(5% + 12px) 1rem 1rem;
            }*/
        }
    }
    &.full{
        padding: 0;
        .item{
            img{
                position: static;
                transform: none;
                max-width: 100%;
                @media screen and (min-width: $lg){
                    width: 100%;
                }
            }
            a{
                position: static;
            }
            .project-name{
                padding: 1rem 2.5% 1rem 1rem;
            }
        }
    }
    &.equal{
        @media screen and (min-width: $lg){
            width: calc(50% - 5px);
        }
        .project-name{
            @media screen and (min-width: $lg){
                padding: 1rem 5.25% 1rem 1rem;
            }
            /*@media screen and (min-width: $xxl){
                padding: 1rem calc(5% + 12px) 1rem 1rem;
            }*/
        }
    }
   
}

.type1{
    @media screen and (min-width: $lg){
        &::before{
            padding-top: 56.14%;
        }
        img{
           /* width: 100%;
            height: auto;*/
           /* height: 100%;
            width: auto;*/
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
.type2{
    @media screen and (min-width: $lg){
        &::before{
            padding-top: 126.36%;
            //padding-top: 100%;
        }
        img{
           /* width: auto;
            height: 100%;*/
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
.type3{
    @media screen and (min-width: $lg){
        &::before{
            padding-top: 77.71%;
            //background: gold;
        }
        img{
           /* width: auto;
            height: 100%;*/
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
.type4{
    @media screen and (min-width: $lg){
        flex-grow: 1;
        img{
           /* width: auto;
            height: 100%;*/
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

.moreprojects{
    background: transparent;
    border-radius: 0;
    border: none;
    font-family: inherit;
    color: $black;
    margin: 2rem 0 2rem 2.5%;
    font-weight: bold;
    display: inline-block;
    font-size: 0.875rem;
    cursor: pointer;

}
.load{
    text-align: center;
    width: 100%;
    img{
        margin:auto;
        display: block;
        max-width: 2rem;
    }
}