.projects-categories{
    .categories-list{
        display: flex;
        justify-content: center;
        a{
            display: inline-block;
            padding: 1rem;
            background: $white;
            color: $darkgrey;
            margin: 0 0.5rem;
            text-transform: uppercase;
            @media screen and (min-width: $md){
                font-size: 1.5rem;
                
            }
            &:hover, &.active{
                background: $darkgrey;
                color: $white;
            }
        }
    }
}
.projects{
    @media screen and (min-width: $md){
        justify-content: space-between;
    }
    &_item{
        @media screen and (min-width: $md){
            width: 65%;
        }
        &.vertical{
            @media screen and (min-width: $md){
                width: 30%;
            }
            .img-wrapper{
                max-width: 100%;
            }
        }
        .img-wrapper{
            max-width: 100%;
            @media screen and (min-width: $md) {
                height: 55vh;
            }
            @media screen and (min-width: $md) and (orientation: portrait) {
                height: 35vh;
            }
            @media screen and (min-width: $md) and (orientation: landscape) {
                height: 75vh;
            }
           
            @media screen and (min-width: $xl){
                height: 75vh;
            }
        }
        img{
            @media screen and (min-width: $md){
                width: 100%;
                height: 100%;
                object-fit: cover;
            }     
        }
    }
}
.about-row{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    @media screen and (min-width: $md){
        flex-direction: row;
    }
    .about-image{
        @media screen and (min-width: $md){
            width: 30%;
        }
    }
    .about-content{
        @media screen and (min-width: $md){
            width: 65%;
            font-size: 1.5rem;
        }
    }
}
.contact{
    min-height: 100vh;
    padding-top: 9rem;
    @media screen and (min-width: $md){
        padding-top: 12rem;
    }
    .contact-content{
        font-size: 0.875rem;
         @media screen and (min-width: $md){
            font-size: 1rem;
        }
        @media screen and (min-width: $xl){
            font-size: 1.125rem;
        }
        /*display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 3rem 0;
        & > div{
            padding: 2rem;
        }*/
        .contact-data{
            font-weight: 600;
            p:nth-child(2){
                margin-left: 40%;
                @media screen and (min-width: $md){
                    margin-left: 13rem;
                }
            }
        }
        .contact-people{
            padding: 1rem 0;
            @media screen and (min-width: $md){
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
            } 
            .contact-people-item{
                padding: 1rem 0;
                font-weight: 600;
                @media screen and (min-width: $md){
                    width: 49%;
                }
                @media screen and (min-width: $lg){
                    width: 28%;
                }
                &:first-child{
                    @media screen and (min-width: $md){
                        width: 100%;
                    }
                    @media screen and (min-width: $lg){
                        width: 28%;
                    }
                }
                img{
                    max-width: 13.75rem;
                    @media screen and (min-width: $md){
                        max-width: 18.125rem;
                    }
                }
            }
        }
    }
    
}

.singleproject-description{
    display: flex;
    justify-content: space-between;
    padding: 3rem 0;
    flex-direction: column;
    @media screen and (min-width: $md){
        flex-direction: row;
        align-items: flex-end;
    }
    .description-column{
        font-size: 0.875rem;
         @media screen and (min-width: $md){
            font-size: 1rem;
        }
        @media screen and (min-width: $xl){
            font-size: 1.125rem;
        }
        
        h2{
            font-weight: 500;
            padding-bottom: 2rem;
        }
        &.short{
            font-weight: 600;
            font-size: 1.313rem;
            @media screen and (min-width: $md){
                width: 30%
            }
            @media screen and (min-width: $xl){
                width: 47.5%;
            }
            @media screen and (min-width: $xxl){
                width: 32%;
            }
        }
        &.long{
            text-align: justify;
            font-size: 0.875rem;
            @media screen and (min-width: $md){
                width: 60%
            }
            @media screen and (min-width: $xl){
                width: 47.5%;
            }
            @media screen and (min-width: $xxl){
                width: 32%;
            }
        }
    }
}

.singleproject-gallery{
    //width: 80%;
    width: 100%;
    /*margin: auto;
    @media screen and (min-width: $md){
        width: 90%;
    }
    @media screen and (min-width: $lg){
        width: 100%;
    }*/
    .gallery-item{
        /*padding: 0 2rem;
        @media screen and (min-width: $md){
            padding: 0 5rem;
        }*/
        margin-bottom: 5px;
        @media screen and (min-width: $lg){
            margin-bottom: 10px;
        }
        img{
            margin: auto;
        }
        &.two-items{
            display: flex;
            justify-content: space-between;
            & > div {
                width: 47.5%;
            }
        }
        &.video{
            text-align: center;
            video{
                max-width: 100%;
            }
        }
    }
    .slick-arrow{
        &::before{
            content:'';
            border-top: 2px solid $black;
            border-right: 2px solid $black;
            display: block;
            width: 100%;
            height: 100%;
        }
        &.slick-prev{
            &::before{
                transform: rotate(-135deg);
            }
        }
        &.slick-next{
            &::before{
                transform: rotate(45deg);
            }
        }
    }
    
}