footer{
    background: $footerbg;
    padding: 1.5rem 0;
    color: $footercolor;
    text-align: center;
    font-weight: 600;
    font-size: 0.875rem;
    /*@media screen and (min-width: $md){
       font-size: 1rem;
    }
    @media screen and (min-width: $xl){
       font-size: 1.125rem;
    }*/
    /*text-align: center;
    svg{
        width: 2.5rem;
        fill: $footericonscolor;
        margin: 0 0.5rem;
        @include shift(fill);
        &:hover{
            fill: $footericonshovcolor;
        }
    }*/
}