body{
    font-weight: 400;
    font-family: $bodyfont;
    color: $fontcolor;
    background: $bodybg;
    //max-width: 1980px;
    margin: auto;
    &.mobile-menu-on {
        overflow: hidden;
    }
    &.highcontrast{
        background: $bodybgcontra;
        color: $fontcolorcontra;
    }
    &.errorpage{
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        main{
            flex-grow: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            img{
                max-width: 9rem;
            }
        }
    }
}
a {
    @include shift;
    text-decoration: none;
    color: $generallinkcolor;
}
.h1_top{
    font-family: $headerfont;
    font-size: 2rem;
    text-transform: uppercase;
    font-weight: 600;
    max-width: 6rem;
    @media screen and (min-width: $md){
        max-width: 9rem;
    }
    &.project_top{
        @media screen and (min-width: $md){
            max-width: 20rem;
        }
        @media screen and (min-width: $lg){
            font-size: 3.125rem;
        }
    }
   
    
    /*@media screen and (min-width: $md){
        font-size: 3.125rem;
        max-width: 35%;
    }
    @media screen and (min-width: $lg){
        max-width: 25%;
    }*/
}

strong {
    font-weight: 600;
}
img{
    max-width: 100%;
    display: block;
}
p{
    padding: 0.5rem 0;
    line-height: 1.4;
}
.container-custom{
    width: $containerwidthmid;
    margin: auto;
    @media screen and (min-width: $lg){
        width: $containerwidth;
    }
}
.center{
    text-align: center;
}
